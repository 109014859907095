import { dropdownToValueObject, isMonthPaid } from "../utils";



export const headersNotificacao = [
  {
    key: "descricao",
    label: "Descrição",
  },
  {
    key: "createdAt",
    label: "Data de criação",
    component: ({ createdAt }) => <span>{new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(createdAt))}</span>
  },
];

export const headersTransacoes = [
  {
    key: "descricao",
    label: "Descrição",
  },
  {
    key: "createdAt",
    label: "Data de criação",
    component: ({ createdAt }) => <span>{new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(createdAt))}</span>
  },
  {
    key: "respondida",
    label: "Status",
    component: (item) => <div className="flex items-center">
      {item.respondida ?
        <><div className="small-circle enabled" /> Respondido</> :
        <><div className="small-circle disabled" /> Pendente</>
      }
    </div>
  },
];

export const tipoTransacao = [
  
  { label: "Cadastrar Empresa", value: "cadastrar-empresa" },
  { label: "Aprovar Contrato", value: "aprovar-empresa" },
  { label: "Reprovar Contrato", value: "reprovar-empresa" },
  { label: "Oferecer Trial", value: "teste" },
  { label: "Pagar Mensalidade", value: "pagamento" },
];

export const tipoTransacaoObject = dropdownToValueObject(tipoTransacao)

export const tiposEmpresa = [
  { label: "Clínica", value: "CLINICA" },
  { label: "Restaurante", value: "RESTAURANTE" },
  { label: "Salão", value: "SALAO" },
];

export const tiposEmpresaObject = dropdownToValueObject(tiposEmpresa)

export const estadosEmpresa = [
  { label: "Aprovada", value: 1 },
  { label: "Não Aprovada", value: 0 },
];

export const estadosPagamento = [
  { label: "Pago", value: 1 },
  { label: "Não Pago", value: 0 },
];

export const estadosEmpresaObject = dropdownToValueObject(estadosEmpresa)

export const estadosPagamentoObject = dropdownToValueObject(estadosPagamento)


export const estadosUsuario = [
  { label: "Ativo", value: 1 },
  { label: "Não Ativo", value: 0 },
];

export const estadosUsuarioObject = dropdownToValueObject(estadosUsuario)

export const funcoesUsuario = [
  { label: 'Administrador', value: "admin" },
  { label: 'Vendedor', value: "vendedor" },
];

export const funcoesUsuarioObject = dropdownToValueObject(funcoesUsuario)

export const pacotesEmpresa = [
  { label: "Básico", value: "BASICO" },
  // { label: "Essencial", value: "ESSENCIAL" },
  { label: "Premium", value: "PREMIUM" },
];
export const pacotesEmpresaObject = dropdownToValueObject(pacotesEmpresa)

export const tempoMinimoEmpresa = [
  { label: '1 Hora', value: "1 Hora" },
  { label: '2 Horas', value: "2 Horas" },
  { label: '1 Dia', value: "1 Dia" },
  { label: '1 Semana', value: "1 Semana" },
  { label: '1 Mês', value: "1 Mês" },
]

export const todos = {
  label: 'Todos', value: ''
}

export const headersVendedor = [
  {
    key: "nome",
    label: "Nome",
  },
  {
    key: "email",
    label: "Email",
  },
  {
    key: "contacto",
    label: "Contacto",
  },
  {
    key: "funcao",
    label: "Função",
    component: (item) => funcoesUsuarioObject[item.funcao].label
  },
  {
    key: "ativo",
    label: "Estado",
    component: (item) => <div className="flex items-center">
      {item.ativo ?
        <><div className="small-circle enabled" /> {estadosUsuarioObject[item.ativo ? 1 : 0].label}</> :
        <><div className="small-circle disabled" /> {estadosUsuarioObject[item.ativo ? 1 : 0].label}</>
      }
    </div>
  },
];

//tabela de activadores

export const headersActivadores = [
  
  {
    key: "nome",
    label: "Nome",
    //component: (item) => <div className="flex items-center"><img className="table-icon" src={item.logotipo} alt={`${item.nome} Logo`} /><span>{item.nome}</span></div>
  },
  {
    key: "contacto",
    label: "Contacto",
    //component: (item) => <div className="flex items-center"><img className="table-icon" src={item.logotipo} alt={`${item.nome} Logo`} /><span>{item.nome}</span></div>
  },
  {
    key: "createdAt",
    label: "Data de criação",
    component: ({ createdAt }) => <span>{new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(createdAt))}</span>
  },
  {
    key: "empresa",
    label: "Empresa",
    //component: ({ createdAt }) => <span>{new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(createdAt))}</span>
  },
 
  {
    key: "usuario.nome",
    label: "Nome do Vendedor",
    //component: ({ createdAt }) => <span>{new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(createdAt))}</span>
  },

];

export const headersEmpresa = [
  {
    key: "nome",
    label: "Nome",
    component: (item) => <div className="flex items-center"><img className="table-icon" src={item.logotipo} alt={`${item.nome} Logo`} /><span>{item.nome}</span></div>
  },
  {
    key: "tipo",
    label: "Tipo",
    component: (item) => tiposEmpresaObject[item.tipo].label
  },
  {
    key: "pacote",
    label: "Pacote",
    component: (item) => pacotesEmpresaObject[item.pacote].label
  },
  {
    key: "endereco",
    label: "Endereço",
  },
  {
    key: "expiracaoPagamento",
    label: "Mensalidade",
    
  },
  {
    key: "aprovado",
    label: "Avaliação",
    component: (item) => <div className="flex items-center">
      {item.aprovado ?
        <><div className="small-circle enabled" /> {estadosEmpresaObject[item.aprovado ? 1 : 0].label}</> :
        <><div className="small-circle disabled" /> {estadosEmpresaObject[item.aprovado ? 1 : 0].label}</>
      }
    </div>
  },
  {
    key: "usuario.nome",
    label: "Nome do vendedor",
  },
  {
    key: "createdAt",
    label: "Data de criação",
    component: ({ createdAt }) => <span>{new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(createdAt))}</span>
  },
];


export const headersCupao = [
  {
    key: "activador.nome",
    label: "Nome do Activador",
    //component: (item) => <div className="flex items-center"><img className="table-icon" src={item.logotipo} alt={`${item.nome} Logo`} /><span>{item.nome}</span></div>
  },
  {
    key: "activador.contacto",
    label: "Contacto",
    
  },
  {
    key: "activador.empresa",
    label: "Empresa",
  },
  {
    key: "quantidade",
    label: "Quantidade",
   
  },

  {
    key: "codigo",
    label: "Cupao",
  },
  {
    key: "validacoes",
    label: "validacoes",

  },
  {
    key: "activador.usuario.nome",
    label: "Nome do vendedor",
  },
  {
    key: "valor",
    label: "valor a pagar (MT)",
    component: (item) => <span>{item.validacoes*25}.00 </span>

    
  },
  {
    key: "pagamento",
    label: "Estado do Pagamento",
    component: (item) => <div className="flex items-center">
      {item.pagamento ?
        <><div className="small-circle enabled" /> {estadosPagamentoObject[item.pagamento ? 1 : 0].label}</> :
        <><div className="small-circle disabled" /> {estadosPagamentoObject[item.pagamento ? 1 : 0].label}</>
      }
    </div>
  },
 
];


