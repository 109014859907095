import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from 'yup';
import BusinessDay from "../../components/input/BusinessDay";
import FileInput from "../../components/input/FileInput";
import RadioButton from "../../components/input/RadioButton";
import TextInput from "../../components/input/TextInput";
import { useAuth } from "../../context";
import { APIKit, uploadFile } from "../../services/api";
import "./create.css";

const EditActivador = () => {
  const { toast } = useAuth(useAuth);
  const [businessDays, setBusinessDays] = useState(null)
  const [activador, setActivador] = useState(null)
  const [document, setDocument] = useState(null)
  const [image, setImage] = useState()
  let { id } = useParams();
  const setBusinessDay = (name, values) => setBusinessDays({ ...businessDays, [name]: values })
  const changeDocument = (file) => {
    setDocument(file)
  }
  const changeImage = (file) => {
    setImage(file)
  }

  const fetchData = useCallback(
    () => {
      APIKit.get(`/activadores/${id}`)
        .then(response => {
          const activador = response.data
          setActivador(activador)
          formik.setValues({
            nome: activador.nome,
            contacto: activador.contacto,
            empresa: activador.empresa,
          })
        
        })
        .catch(() => toast.error("Erro ao Buscar Activadores!"))
        .finally(() => { })
    },
    [],
  )
  useEffect(() => {
    fetchData()
  }, [])

  const formik = useFormik({
    initialValues: {
      nome: "",
      contacto: "",
      empresa: "",
    },
    validationSchema: Yup.object({
      nome: Yup.string().required('Campo Nome é obrigatório'),
      contacto: Yup.string().required('Campo Tipo de Empresa é obrigatório'),
      empresa: Yup.string().required('Campo Pacote é obrigatório'),
    }),
    onSubmit: async (values) => {
   
      try {

        const toastID = toast.loading('Enviando Dados!');
        APIKit.put(`/activadores/${id}`, values)
          .then(() => { toast.success('Activador atualizada com sucesso!'); })
          .catch(() => { toast.error('Ocorreu um erro ao Actualizar o Activador!') })
          .finally(() => toast.dismiss(toastID))
      } catch (err) {
        toast.error('Ups, ocorreu um erro,tente novamente!');
      }

    },
  });

  
  if (!activador) return <></>
  return (
    <div className="container">
      <div className="form-header">
        <h2 className="gradient-text"> Editar dados da Activador</h2>
      </div>
      <form onSubmit={(e) => {
        e.preventDefault();
        if (formik.isValid) formik.submitForm()
        else {
          toast.error(`Formulário incompleto!`);
          toast.error(Object.values(formik.errors).join('\n').toString());
        }
      }} className="mb-4">
        <h3 className="mb-1 gradient-text font-bold">DETALHES DO ACTIVADOR</h3>
        <p className="mb-4">
          Nesta secção irá preencher informação referente aos detalhes do activador
        </p>
        <TextInput
          className="card mb-3"
          name="nome"
          label="Nome "
          //disabled
          placeholder="Digite a Resposta"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values?.nome}
          
        />
       
        <TextInput
          className="card mb-3"
          name="contacto"
          label="Contacto do Activador"
          placeholder="Digite a Resposta"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.contacto}
        />
        <TextInput
          className="card mb-3"
          name="empresa"
          label="Empresa do Activador"
          placeholder="Digit"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.empresa}
        />
        <div className="flex">
          <button type="submit" className="action-button" to="/admin">
            <span>Salvar</span>
          </button>
        </div>
      </form>
    </div >
  );
};
export default EditActivador;
