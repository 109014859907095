import React from "react";

const RejectedIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.6328 10.5919L22.787 9.1215V7.40187C22.787 6.38006 22.2398 5.45794 21.3691 4.9595L19.9013 4.11215L19.0555 2.64174C18.558 1.76947 17.6127 1.22118 16.6176 1.22118H14.9011L13.4334 0.373832C12.5627 -0.124611 11.493 -0.124611 10.6223 0.373832L9.15462 1.22118H7.43813C6.41818 1.22118 5.49775 1.76947 5.00021 2.64174L4.15441 4.11215L2.63693 4.9595C1.76624 5.45794 1.21896 6.40498 1.21896 7.40187V9.1215L0.37315 10.5919C-0.124383 11.4642 -0.124383 12.5358 0.37315 13.4081L1.21896 14.8785V16.5981C1.21896 17.6199 1.76624 18.5421 2.63693 19.0405L4.10465 19.8879L4.95046 21.3583C5.44799 22.2305 6.39331 22.7788 7.38837 22.7788H9.10486L10.5726 23.6262C11.4433 24.1246 12.513 24.1246 13.3837 23.6262L14.8514 22.7788H16.5679C17.5878 22.7788 18.5083 22.2305 19.0058 21.3583L19.8516 19.8879L21.3193 19.0405C22.19 18.5421 22.7373 17.595 22.7373 16.5981V14.8785L23.5831 13.4081C24.1304 12.5358 24.1304 11.4642 23.6328 10.5919Z" fill="#F75048" />
      <path d="M16.3191 8.4486L15.5231 7.65109L11.9906 11.215L8.48295 7.65109L7.6869 8.4486L11.2194 11.9875L7.6869 15.5514L8.48295 16.3489L11.9906 12.785L15.5231 16.3489L16.3191 15.5514L12.7866 11.9875L16.3191 8.4486Z" fill="white" />
    </svg>
  );
};
export default RejectedIcon;
