import React from "react";

const CheckIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 512 382"
      xmlns="http://www.w3.org/2000/svg"
      fill="white"
    >
      <path d="M173.898 374.404L7.49799 208.004C-2.49901 198.007 -2.49901 181.798 7.49799 171.8L43.701 135.596C53.698 125.598 69.908 125.598 79.905 135.596L192 247.69L432.095 7.59589C442.092 -2.40111 458.302 -2.40111 468.299 7.59589L504.502 43.7999C514.499 53.7969 514.499 70.0059 504.502 80.0039L210.102 374.405C200.104 384.402 183.895 384.402 173.898 374.404V374.404Z" />
    </svg>
  );
};
export default CheckIcon;
