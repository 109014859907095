import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import CustomTable from "../../components/CustomTable";
import Dropdown from "../../components/input/Dropdown";
import TextInput from "../../components/input/TextInput";
import Pagination from "../../components/Pagination";
import { estadosEmpresa,estadosPagamento, headersCupao, pacotesEmpresa, tiposEmpresa } from "../../constants/dictionary";
import { useAuth } from "../../context";
import { APIKit } from "../../services/api";
import { itemPlusTodos } from "../../utils";
import "./empresa.css";
import CriarCupons from "./Modal/index";


const Empresas = () => {
  const { toast, usuario } = useAuth(useAuth)
  const [empresas, setEmpresas] = useState(null)
  const [cupons, setCupons] = useState(null)
  const query = useParams();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const history = useHistory();
  const [filters, setFilters] = useState({
    activadorNome: '',
    pagamento: '',
    usuarioNome: '',
    pagamentos: '',
  })
  const setFilter = (key, value) => {
    const data = { ...filters, [key]: value }
    if (key !== 'page') data.page = 0
    setFilters(data)
  }

  const fetchData = () => {
    APIKit.get('/cupoes', {
      params: { ...filters }
    }).then(response => setEmpresas(response.data)).catch((err) => toast.error("Erro ao Buscar cupons!"))
  }

const menuItems = (item) => [

    {
      
      text: "Efectuar Pagamento",
      
      onClick: () => {
        if(item.pagamento==1){
          toast.error(`O pagamento ja foi efectuado.`)
        } else{ 
        if (usuario.funcao === 'vendedor')
        if(item.quantidade!=0){
          toast.error(`Erro ao Efectuar Pagamento, Certifique-se que a quantidade do cupão esta esgotada!`)
        }else {
        APIKit.put(`/cupoes/${item.id}`)
        .then(() => { toast.success(`Pagamento Efectuado com Sucesso!`) })
        .catch(() => { toast.error(`'Aconteceu algum erro, tente mais tarde ou contacte o administrador`) })
        .finally(() => fetchData())
      }}},
    },
  ];

  useEffect(() => {
    fetchData()
  }, [filters])

  useEffect(() => {
    setFilters({ ...query })
  }, [])
  return (
    
    
    <div className="container">
      <div className="w-full">
        <h2 className="gradient-text">Cupons</h2>
        <p>Lista de Cupons cadastrados.</p>

        <div className="w-full flex">
        <div className="flex-col flex-1">
          <h2 className="gradient-text"></h2>
          <p></p>
        </div>
        <div className="flex-col">
        
        </div>
      </div>
      
      </div>
      <div className="flex-col w-full">
        <h3 className="mb-1 mt-4 gradient-text">Filtros</h3>
        <div className="grid-col-4 gap-4 w-full">  
          <TextInput
            name="activadorNome"
            label="Nome do Activador"
            type="text"
            placeholder=" Activador"
            inputEvent={(data) => setFilter('activadorNome', data)}
          />
        
           <TextInput
            name="usuarioNome"
            label="Nome do Vendedor"
            type="text"
            placeholder="Nome do Vendedor"
            inputEvent={(data) => setFilter('usuarioNome', data)}
          />
         
         {/* <Dropdown
            name="pagamento"
            className="no-shadow"
            items={itemPlusTodos(estadosPagamento)}
            label="Estado do pagamento"
            placeholder="Tipo de Empresa"
            inputEvent={(data) => setFilter('pagamento', data)}
          /> */}
        


        </div>
      </div>
      <CustomTable header={headersCupao} items={empresas?.items} menuItems={menuItems} />
      {(empresas && empresas.totalItems) ?
        <Pagination
          totalPages={empresas.totalPages}
          currentPage={empresas.currentPage}
          totalItems={empresas.totalItems}
          changePage={(id) => setFilter('page', id)}
        />
        : null}

         {isModalVisible ? (
        <CriarCupons fetchData={fetchData} onClose={() => setIsModalVisible(false)}>
          <h4>fsdfdf</h4>
        </CriarCupons>
      ) : null}

      
    </div>
  );
};
export default Empresas;
