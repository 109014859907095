import { useFormik } from "formik";
import React from "react";
import * as Yup from 'yup'; import { IoMdClose } from 'react-icons/io';
import RadioButton from "../../../components/input/RadioButton";
import TextInput from "../../../components/input/TextInput";
import { APIKit } from "../../../services/api";
import { useAuth } from "../../../context";
import "./modal.css";
import { funcoesUsuario } from "../../../constants/dictionary";
const CriarActivadores = ({ onClose = () => { }, fetchData }) => {

  const { toast,usuario } = useAuth(useAuth)
  const formik = useFormik({
    initialValues: {
      nome: '',
      contacto: '',
      usuarioId:usuario.id
    },
    validationSchema: Yup.object({
      nome: Yup.string().required('Required'),
      contacto: Yup.string().required('Required'),
    }),
    onSubmit: (values, { resetForm }) => {
      toast.promise(APIKit.post('/activadores', values), {

        loading: 'Enviando!',
        success: 'Criado com sucesso!',
        error: 'Ups, ocorreu um erro!',
      });
      fetchData()
      resetForm()
    },
  });
  return (
    <div className="modal" >
      <form className="modal-container" onSubmit={formik.handleSubmit}>
        <div className="close">
          <button onClick={onClose}><IoMdClose size={32} color="red" /></button>
        </div>
        <h3 className="gradient-text text-center mb-2">Adicione Activadores</h3>
        <div className="modal-content">
          <TextInput
            className="card mb-3 w-full"
            name="nome"
            label="Nome Completo"
            placeholder="Nome Completo"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.nome}
          />
          <TextInput
            className="card mb-3 w-full"
            name="contacto"
            label="Telefone"
            placeholder="Telefone"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.contacto}
          />

        <TextInput
            className="card mb-3 w-full"
            name="empresa"
            label="Empresa"
            placeholder="Empresa"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.empresa}
          />
         
          <div className="flex w-full">
            <button type="submit" className="action-button w-full pv-4" to="/admin">
              <span>Cadastrar</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
export default CriarActivadores;