import React from "react";

const ApprovedIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.6296 10.6168L22.783 9.14642V7.42679C22.783 6.40498 22.2352 5.48287 21.3637 4.98442L19.8946 4.13707L19.0481 2.66667C18.5501 1.79439 17.6039 1.24611 16.6079 1.24611H14.8899L13.3959 0.373832C12.5244 -0.124611 11.4537 -0.124611 10.5823 0.373832L9.11319 1.22118H7.39513C6.37425 1.22118 5.45298 1.76947 4.95499 2.64174L4.10841 4.11215L2.63934 4.9595C1.76786 5.45794 1.22007 6.40498 1.22007 7.40187V9.12149L0.373492 10.5919C-0.124497 11.4642 -0.124497 12.5358 0.373492 13.4081L1.22007 14.8785V16.5981C1.22007 17.6199 1.76786 18.5421 2.63934 19.0405L4.10841 19.8879L4.95499 21.3583C5.45298 22.2305 6.39915 22.7788 7.39513 22.7788H9.11319L10.5823 23.6262C11.4537 24.1246 12.5244 24.1246 13.3959 23.6262L14.865 22.7788H16.583C17.6039 22.7788 18.5252 22.2305 19.0232 21.3583L19.8697 19.8879L21.3388 19.0405C22.2103 18.5421 22.7581 17.595 22.7581 16.5981V14.8785L23.6047 13.4081C24.1276 12.5607 24.1276 11.4891 23.6296 10.6168Z" fill="#43D422" />
      <path d="M10.9558 14.2056L7.66904 10.9159L6.62326 11.9626L11.0056 16.3739L17.3549 9.24613L16.2593 8.27417L10.9558 14.2056Z" fill="white" />
    </svg>
  );
};
export default ApprovedIcon;
