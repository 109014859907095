import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import CustomTable from "../../../components/CustomTable";
import Dropdown from "../../../components/input/Dropdown";
import TextInput from "../../../components/input/TextInput";
import Pagination from "../../../components/Pagination";
import { headersTransacoes, tipoTransacao } from "../../../constants/dictionary";
import { useAuth } from "../../../context";
import { APIKit } from "../../../services/api";
import { itemPlusTodos } from "../../../utils";

const Transacoes = () => {
  const { toast } = useAuth(useAuth);
  const [movimentos, setMovimentos] = useState([])
  const history = useHistory();
  const query = useParams();
  const [filters, setFilters] = useState({
    page: 0,
  })
  const setFilter = (key, value) => {
    const data = { ...filters, [key]: value }
    if (key !== 'page') data.page = 0
    setFilters(data)
  }

  const fetchData = () => {
    APIKit.get('/movimentos/transacoes', {
      params: { ...filters }
    }).then(response => setMovimentos(response.data)).catch((err) => toast.error("Erro ao Buscar transações!"))
  }

  useEffect(() => {
    fetchData()
  }, [filters])

  useEffect(() => {
    setFilters({ ...query })
  }, [])

  const menuItems = (item) => [
    {
      text: "Ver Empresa",
      onClick: () => {
        history.push(`/empresas/${item.empresa.id}`);
      },
    },
    {
      text: "Ver Usuário",
      onClick: () => {
        history.push(`/usuarios/${item.usuario.id}`);
      },
    },
  ];
  return (
    <div className="container">
      <div className="w-full">
        <h2 className="gradient-text">Painel de Transações</h2>
        <p>Veja todas transações.</p>
      </div>
      <div className="w-full">
        <h3 className="mb-1 mt-4 gradient-text">Filtros</h3>
        <div className="grid-col-4 gap-2">
          <TextInput
            name="startDate"
            label="Data Inicial"
            type="date"
            placeholder="Data Inicial"
            inputEvent={(data) => setFilter('startDate', data)}
          />
          <TextInput
            name="endDate"
            label="Data Final"
            type="date"
            placeholder="Data Final"
            inputEvent={(data) => setFilter('endDate', data)}
          />
          <Dropdown
            name="tipo"
            items={itemPlusTodos(tipoTransacao)}
            label="Tipo de transação"
            placeholder="Tipo de transação"
            inputEvent={(data) => setFilter('tipo', data)}
          />
        </div>
      </div>
      <CustomTable header={headersTransacoes} items={movimentos?.items} menuItems={menuItems} />
      {(movimentos && movimentos.totalItems) ?
        <Pagination
          totalPages={movimentos.totalPages}
          currentPage={movimentos.currentPage}
          totalItems={movimentos.totalItems}
          changePage={(id) => setFilter('page', id)}
        />
        : null}
    </div>
  );
};
export default Transacoes;
