import React from "react";

const FileIcon = () => {
  return (
    <svg viewBox="0 0 45 40" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.0445 33.1359C16.814 32.6734 16.4124 32.6266 16.2499 32.6266C16.0874 32.6266 15.6859 32.6727 15.4671 33.1102L14.8679 34.3086C14.3702 35.307 12.9124 35.1969 12.5648 34.1453L11.25 30.2023L10.421 32.693C9.96089 34.0727 8.67262 35 7.21794 35H6.24997C5.55935 35 4.99998 34.4406 4.99998 33.75C4.99998 33.0594 5.55935 32.5 6.24997 32.5H7.21794C7.59528 32.5 7.92965 32.2594 8.04918 31.9016L9.47027 27.6328C9.72808 26.8664 10.4421 26.3508 11.25 26.3508C12.0578 26.3508 12.7718 26.8656 13.0288 27.6328L14.1132 30.8859C15.6562 29.6211 18.3366 30.1281 19.2694 31.9922C19.4171 32.2875 19.6984 32.457 20.0007 32.4813V26.0656L30.0007 16.1367V12.5H19.3749C18.3437 12.5 17.4999 11.6562 17.4999 10.625V0H1.87499C0.835934 0 0 0.835937 0 1.875V38.125C0 39.1641 0.835934 40 1.87499 40H28.1249C29.1639 40 29.9999 39.1641 29.9999 38.125V35L19.9999 34.9914C18.7405 34.9672 17.6109 34.2664 17.0445 33.1359V33.1359ZM29.9999 9.52344C29.9999 9.03125 29.8046 8.55469 29.453 8.20312L21.8046 0.546875C21.453 0.195312 20.9765 0 20.4765 0H19.9999V10H29.9999V9.52344ZM22.4999 27.1062V32.5H27.8897L40.5209 19.7828L35.2178 14.4797L22.4999 27.1062V27.1062ZM44.417 13.0727L41.9272 10.5828C41.1506 9.80625 39.8905 9.80625 39.1139 10.5828L36.985 12.7117L42.2881 18.0148L44.417 15.8859C45.1943 15.1094 45.1943 13.8492 44.417 13.0727Z" />
    </svg>
  );
};
export default FileIcon;
