import React from "react";

const UserIcon = () => {
  return (
    <svg width="23" height="25" viewBox="0 0 23 25" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.5 12.3282C7.96495 12.3282 5.10114 9.57904 5.10114 6.18557C5.10114 2.7921 7.96495 0 11.5 0C15.035 0 17.8988 2.74914 17.8988 6.14261C17.8988 9.53608 15.035 12.3282 11.5 12.3282ZM11.5 2.57732C9.4416 2.57732 7.78596 4.16667 7.78596 6.14261C7.78596 8.11856 9.4416 9.7079 11.5 9.7079C13.5583 9.7079 15.214 8.11856 15.214 6.14261C15.214 4.16667 13.5583 2.57732 11.5 2.57732Z" />
      <path d="M23 25H20.3152C20.3152 20.3178 16.3774 16.5378 11.5 16.5378C6.62257 16.5378 2.68482 20.3178 2.68482 25H0C0 18.9003 5.14591 13.9604 11.5 13.9604C17.8541 13.9604 23 18.9003 23 25Z" />
    </svg>

  );
};
export default UserIcon;
