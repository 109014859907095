import React from "react";
import { AiOutlineBars, FiUser, FiUsers } from "react-icons/all";
import { BrowserRouter as Router, NavLink, Route, Switch } from "react-router-dom";
import Logo from "../../assets/svgs/logoAdmin.svg";
import Dashboard from "../Dashboard";
import Empresas from "../Empresa";
import Activadores from "../Activadores";
import Cupons from "../Cupons";
import VerEmpresa from "../Empresa/id";
import CreateEmpresa from "../Empresa/create";
import EditEmpresa from "../Empresa/edit";
import EditActivador from "../Activadores/edit";
import "./layout.css";
import Perfil from "./Perfil";
const Layout = () => {
  return (
    <Router>
      <header>
        <nav>
          <div className="logo">
            <img src={Logo} alt="Logo" />
          </div>

          <div className="nav-list">
            <NavLink exact to="/">
              <AiOutlineBars /> Formulário
            </NavLink>
            <NavLink to="/empresas">
              <FiUsers /> Empresas
            </NavLink>
            <NavLink to="/Activadores">
              <FiUser /> Activadores
            </NavLink>
            <NavLink to="/Cupons">
              <FiUser /> Cupons
            </NavLink>
            <NavLink to="/dashboard">
              <FiUser /> Dashboard
            </NavLink>
            <NavLink to="/perfil">
              <FiUser /> Perfil
            </NavLink>
            {/* <NavLink to="/Vendedores">
              <FiUser /> Vendedores
            </NavLink> */}
          </div>
        </nav>
      </header>
      <main className="seller">
        <Switch>
          <Route exact path="/empresas/edit/:id">
            <EditEmpresa />
          </Route>
          
          <Route path="/empresas/:id">
            <VerEmpresa />
          </Route>
          <Route exact path="/empresas">
            <Empresas />
          </Route>
          <Route exact path="/activadores">
            <Activadores />
          </Route>

          <Route exact path="/activadores/edit/:id">
            <EditActivador />
          </Route>

          <Route exact path="/cupons">
            <Cupons />
          </Route>
          <Route path="/perfil">
            <Perfil />
          </Route>
          <Route path="/dashboard">
            <Dashboard />
          </Route>

       

          <Route exact path="/">
            <CreateEmpresa />
          </Route>
        </Switch>
      </main>
    </Router>
  );
};
export default Layout;
