import React from "react";

const ArrowRight2Icon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.2929 19.7071C11.9024 19.3166 11.9024 18.6834 12.2929 18.2929L18.5858 12L12.2929 5.70711C11.9024 5.31658 11.9024 4.68342 12.2929 4.29289C12.6834 3.90237 13.3166 3.90237 13.7071 4.29289L20.7071 11.2929C21.0976 11.6834 21.0976 12.3166 20.7071 12.7071L13.7071 19.7071C13.3166 20.0976 12.6834 20.0976 12.2929 19.7071ZM4.29289 19.7071C3.90237 19.3166 3.90237 18.6834 4.29289 18.2929L10.5858 12L4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L12.7071 11.2929C13.0976 11.6834 13.0976 12.3166 12.7071 12.7071L5.70711 19.7071C5.31658 20.0976 4.68342 20.0976 4.29289 19.7071Z" />
    </svg>
  );
};
export default ArrowRight2Icon;
