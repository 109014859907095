
import React from "react";

const SellerIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 91.76 79.75">
      <title>Asset 1</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path d="M39.89,61.38H1a4.09,4.09,0,0,1-.7,0A.49.49,0,0,1,0,61.09C0,59,0,57,0,54.93c0-.33.23-.28.43-.28H18.67c.61,0,.63,0,.63-.65,0-4.5,0-9,0-13.49A14,14,0,0,1,23.88,30a13.82,13.82,0,0,1,9.55-3.84c4.33-.05,8.65,0,13,0a14.26,14.26,0,0,1,13.46,9.87,15.36,15.36,0,0,1,.71,4.85q0,6.51,0,13c0,.73,0,.74.74.74H79.23c.64,0,.64,0,.64.64,0,1.86,0,3.72,0,5.58,0,.42-.12.52-.52.52H39.89ZM30,47.07h0c0-2.35,0-4.71,0-7.07,0-.38-.09-.56-.52-.53a11.16,11.16,0,0,1-1.53,0c-.45,0-.58.13-.58.57,0,2.16,0,4.31,0,6.46v7.6c0,.54,0,.56.57.56s1,0,1.5,0,.55-.13.54-.55C30,51.76,30,49.41,30,47.07Zm19.89,0v7.07c0,.55,0,.56.57.56s1,0,1.54,0,.51-.14.5-.52c0-1.3,0-2.6,0-3.9V40.05c0-.58,0-.58-.59-.58H50.44c-.56,0-.56,0-.56.57Z" />
          <path d="M40.37,64.24H73.52c.63,0,.63,0,.63.62,0,4.79,0,9.58,0,14.37,0,.4-.1.52-.51.52q-33.3,0-66.61,0c-.37,0-.5-.08-.5-.48q0-7.23,0-14.46c0-.57,0-.57.59-.57Z" />
          <path d="M39.9,24.28A12.14,12.14,0,1,1,52.09,12.22,12.12,12.12,0,0,1,39.9,24.28Z" />
          <path d="M63.69,11.49c-.13-.11-.24-.24-.38-.34-.44-.32-.89-.63-1.32-1a.59.59,0,0,1-.23-.37c0-1.23,0-2.46,0-3.69a2.78,2.78,0,0,1,1-1.93,3.7,3.7,0,0,1,2.68-.8H80.26A4.83,4.83,0,0,1,83.8,4.87Q87,8.13,90.32,11.34a4.52,4.52,0,0,1,1.42,3.32c0,7.33,0,14.65,0,22,0,1-1.19,2.71-2.32,2.79-.46,0-.92.15-1.38.15H64.85a3.2,3.2,0,0,1-3.1-3.1c0-2.9,0-5.8,0-8.7a2.11,2.11,0,0,1,.06-.36,6.61,6.61,0,0,0,.73.7,3,3,0,0,1,1.3,3c-.11,1.71,0,3.43,0,5.15,0,.94.34,1.2,1.21,1.19H70.6c5.93,0,11.87,0,17.8,0,.94,0,1.28-.28,1.28-1.27V17.26a2.08,2.08,0,0,0-.45-1.38,1.69,1.69,0,0,0-1.47-.63c-1.77,0-3.54,0-5.31,0a2.72,2.72,0,0,1-1.92-.73,2.49,2.49,0,0,1-.74-1.9c0-1.7,0-3.4,0-5.1a1.67,1.67,0,0,0-.65-1.62A2.43,2.43,0,0,0,78,5.47c-4.38,0-8.76,0-13.15,0a1.06,1.06,0,0,0-1.06,1.1c0,1.5,0,3,0,4.49v.38Z" />
          <path d="M73.72,25.53a6.81,6.81,0,0,0-4.81,4.82,2.7,2.7,0,0,1-.25-.18l-7.82-7.76C59.42,21,58,19.59,56.55,18.19a1.25,1.25,0,0,1-.33-1.75A6.71,6.71,0,0,1,58,14a4.85,4.85,0,0,1,2.42-1.24,1.3,1.3,0,0,1,.87.36c2.79,2.75,5.55,5.51,8.33,8.27l4,3.95A1.32,1.32,0,0,1,73.72,25.53Z" />
          <path d="M72,19.67l.47,0H86.31c.4,0,.58.08.56.53,0,.68-.05.73-.58.73-4.17,0-8.34,0-12.51,0a2.15,2.15,0,0,1-1.07-.48A4,4,0,0,1,72,19.67Z" />
          <path d="M72.25,12.8c-1.66,0-3.32,0-5,0-.43,0-.6-.1-.58-.57,0-.7,0-.7.73-.71,3.28,0,6.57,0,9.85,0,.4,0,.58.11.52.53a1.5,1.5,0,0,0,0,.3c0,.37-.12.46-.46.45C75.64,12.79,74,12.8,72.25,12.8Z" />
          <path d="M76.2,23.72c.11,0,.25,0,.38,0H86.3c.33,0,.59,0,.57.47,0,.73-.05.8-.6.8H77.61a.79.79,0,0,1-.53-.22C76.77,24.43,76.51,24.08,76.2,23.72Z" />
          <path d="M78,27.77l.47,0h7.9c.42,0,.59.12.53.54a.81.81,0,0,0,0,.22c.06.4-.09.53-.51.53-2.1,0-4.21,0-6.31,0-.44,0-.88,0-1.32,0a.52.52,0,0,1-.61-.48C78.07,28.31,78,28.07,78,27.77Z" />
          <path d="M68,15.58h9.2c.2,0,.55.06.57.15a3.72,3.72,0,0,1,0,1s-.2.1-.31.1c-2.63,0-5.26,0-7.9,0a1.53,1.53,0,0,1-.79-.31c-.31-.23-.56-.53-.85-.79Z" />
          <path d="M79.13,31.82l.43,0c2.27,0,4.54,0,6.81,0,.39,0,.55.11.49.5a1.37,1.37,0,0,0,0,.35c0,.31-.08.44-.42.44H79.72c-.31,0-.45-.1-.46-.42A7.91,7.91,0,0,0,79.13,31.82Z" />
          <path d="M76.52,32.92c-.11,0-.24.11-.33.08-1-.31-2-.66-3-1a.35.35,0,0,1-.25-.54,3.64,3.64,0,0,1,2.22-2,.42.42,0,0,1,.38.2c.34,1,.64,2,1,3C76.48,32.72,76.49,32.76,76.52,32.92Z" />
        </g>
      </g>
    </svg>
  );
};
export default SellerIcon;
