import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import CustomTable from "../../components/CustomTable";
import Dropdown from "../../components/input/Dropdown";
import TextInput from "../../components/input/TextInput";
import Pagination from "../../components/Pagination";
import { estadosEmpresa, headersActivadores, pacotesEmpresa, tiposEmpresa } from "../../constants/dictionary";
import { useAuth } from "../../context";
import { APIKit } from "../../services/api";
import { itemPlusTodos } from "../../utils";
import "./empresa.css";
import CriarActivadores from "./Modal/index";
import CriarCupoes from "./Modal2/index";

const Activadores = () => {
  const { toast, usuario } = useAuth(useAuth)
  //const [empresas, setActivadores] = useState(null)
  const [activador, setActivador] = useState(null)
  const query = useParams();
  const [isModalVisible,setIsModalVisible] = useState(false);
  const [isModal2Visible,setIsModal2Visible] = useState(false);
  const history = useHistory();
  const [filters, setFilters] = useState({
    
    numero: '',
    nome: '',
    page: 0,
  })

  const setFilter = (key, value) => {
    const data = { ...filters, [key]: value }
    if (key !== 'page') data.page = 0
    setFilters(data)
  }

  const fetchData = () => {
    APIKit.get('/Activadores', {
      params: { ...filters }
    //}).then(response => setEmpresas(response.data)).catch((err) => toast.error("Erro ao Buscar Activadores!"))
  }).then(response => setActivador(response.data)).catch((err) => toast.error(""))
  }

  const menuItems = (item) => [
    {
      text: "Gerar Cupons",
      onClick: () => {
        setActivador(item.id);
        setIsModal2Visible(true);
       
        //history.push(`/empresas/${item.id}`);
      },
    },
    {
      text: "Editar",
      onClick: () => {
        history.push(`/activadores/edit/${item.id}`);
      },
    },
  ];

  useEffect(() => {
    fetchData()
  }, [filters])

  useEffect(() => {
    setFilters({ ...query })
  }, [])
  return (


    <div className="container">
      <div className="w-full">
        <h2 className="gradient-text">Activadores</h2>
        <p>Lista dos Activadores cadastrados.</p>

        <div className="w-full flex">
          <div className="flex-col flex-1">
            <h2 className="gradient-text"></h2>
            <p></p>
          </div>
          <div className="flex-col">
            <button className="action-button" onClick={() => setIsModalVisible(true)}>
              Cadastrar Activadores
            </button>
          </div>
        </div>

      </div>
      <div className="flex-col w-full">
        <h3 className="mb-1 mt-4 gradient-text">Filtros</h3>
        <div className="grid-col-4 gap-4 w-full">
          <TextInput
            name="nome"
            label="Nome"
            type="text"
            placeholder="Nome do Activador"
            inputEvent={(data) => setFilter('nome', data)}
          />
        

        </div>
      </div>
      <CustomTable header={headersActivadores} items={activador?.items} menuItems={menuItems} />
      {(activador && activador.totalItems) ?
        <Pagination
          totalPages={activador.totalPages}
          currentPage={activador.currentPage}
          totalItems={activador.totalItems}
          changePage={(id) => setFilter('page', id)}
        />
        : null}

      {isModalVisible ? (
        <CriarActivadores fetchData={fetchData} onClose={() => setIsModalVisible(false)}>
          <h4>fsdfdf</h4>
        </CriarActivadores>
      ) : null}

      {isModal2Visible ? (
        <CriarCupoes fetchData={fetchData} id={activador} onClose={() => setIsModal2Visible(false)}>
          <h4>fsdfdf</h4>
        </CriarCupoes>
      ) : null}


    </div>
  );
};
export default Activadores;
