import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from 'yup'; import { IoMdClose } from 'react-icons/io';
import RadioButton from "../../../components/input/RadioButton";
import TextInput from "../../../components/input/TextInput";
import { APIKit } from "../../../services/api";
import { useAuth } from "../../../context";
import "./modal.css";
import { useParams, useHistory } from "react-router-dom"; 
const CriarCupoes = ({ onClose = () => { }, fetchData,id }) => {

  const { toast,usuario } = useAuth(useAuth)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const formik = useFormik({
    initialValues: {
      quantidade: '',
      activadorId:id
    },
    validationSchema: Yup.object({
      quantidade: Yup.string().required('Required'),
    }),
    onSubmit: (values, { resetForm }) => {
      //values.activadorId=id;
      toast.promise(APIKit.post('/cupoes', values), {

        loading: 'Enviando!',
        success: 'Cupao Gerado com Sucesso',
        error: 'Ups, ocorreu um erro!',
      });
      fetchData()
      resetForm()
    },
  });
  return (
    <div className="modal" >
      <form className="modal-container" onSubmit={formik.handleSubmit}>
        <div className="close">
          <button onClick={onClose}><IoMdClose size={32} color="red" /></button>
        </div>
        <h3 className="gradient-text text-center mb-2">Gerar cupons</h3>
        <div className="modal-content">
        <TextInput
            className="card mb-3 w-full"
            name="quantidade"
            label="quantidade"
            placeholder="indique a quantidade para este cupao"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.contacto}
          />
          <div className="flex w-full">
            <button type="submit" className="action-button w-full pv-4" to="/admin">
              <span>Gerar</span>
            </button>
          </div>
        </div>
      </form>
     
    </div>
  );
};
export default CriarCupoes;
