import React from "react";
import { BiTransferAlt, FiBell, FiHome, FiLayers, FiPower, FiUsers, MdKeyboardArrowRight } from "react-icons/all";
import { FiUser } from "react-icons/fi";
import {
  Link,
  NavLink,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import Logo from "../../assets/svgs/logoAdmin.svg";
import UserIcon from "../../assets/svgs/user";
import { funcoesUsuarioObject } from "../../constants/dictionary";
import { pacotesEmpresaObject } from "../../constants/dictionary";

import { useAuth } from "../../context";
import Dashboard from "../Dashboard";
import Empresas from "../Empresa";
//import Empresas from "../Cupons";
import EditEmpresa from "../Empresa/edit";
import EditActivador from "../Activadores/edit";
import VerEmpresa from "../Empresa/id";
import Perfil from "../Vendedor/Perfil";
import "./layout.css";
import Notificacao from "./Notificacao";
import Transacoes from "./Transacoes";
import Vendedores from "./Vendedores";
import Activadores from "../Activadores";
import Cupons from "../Cupons";

const Admin = () => {
  const { logout, usuario } = useAuth(useAuth);
  return (
    <div className="container-admin">
      <aside>
        <div className="aside_1">
          <img src={Logo} alt="Logo" />
        </div>
        <div className="aside_2">
          <NavLink
            to="/dashboard"
          >
            <div className="flex"><FiHome /> Painel <MdKeyboardArrowRight size={24} className="self-end" /></div>
          </NavLink>

          <NavLink

            to="/empresas"
          >
            <div className="flex"><FiLayers /> Empresas <MdKeyboardArrowRight size={24} className="self-end" /></div>
          </NavLink>

          <NavLink

            to="/Cupons"
            >
            <div className="flex"><FiLayers /> Cupons<MdKeyboardArrowRight size={24} className="self-end" /></div>
            </NavLink>
           
            <NavLink

            to="/activadores"
            >
            <div className="flex"><FiLayers /> Activadores <MdKeyboardArrowRight size={24} className="self-end" /></div>
            </NavLink>
          <NavLink

            to="/usuarios"
          >
            <div className="flex"><FiUsers /> Usuários <MdKeyboardArrowRight size={24} className="self-end" /></div>
          </NavLink>
          <NavLink

            to="/notificacao"
          >
            <div className="flex"><FiBell /> Notificações <MdKeyboardArrowRight size={24} className="self-end" /></div>
          </NavLink>
          <NavLink

            to="/transacoes"
          >
            <div className="flex"><BiTransferAlt /> Transações <MdKeyboardArrowRight size={24} className="self-end" /></div>
          </NavLink>
        </div>
      </aside>
      <header className="navbar-admin">

        <div className="profile">
          <div className="flex-col mr-2">
            <span className="font-bold">{usuario.nome}</span>
            <span className="gradient-text text-sm">{funcoesUsuarioObject[usuario.funcao].label}</span>
          </div>
          <div className="dropdown">
            <div className="user-logo">
              <UserIcon size={16} />
            </div>
            <div className="dropdown-content">
              <Link to="/perfil">
                <FiUser size={16} /> Meu Perfil
              </Link>
              <button type="button" className="flex items-center" onClick={logout}>
                <FiPower size={16} /> Logout
              </button>
            </div>
          </div>
        </div>
      </header>
      <main className="main-admin ph-4">
        <Switch>
          <Route exact path="/empresas/edit/:id">
            <EditEmpresa />
          </Route>
          <Route path="/empresas/:id">
            <VerEmpresa />
          </Route>
          <Route exact path="/empresas">
            <Empresas />
          </Route>

          <Route exact path="/cupons">
            <Cupons/>
          </Route>

         
          <Route exact path="/activadores">
            <Activadores />
          </Route>

          <Route exact path="/activadores/edit/:id">
            <EditActivador />
          </Route>

          <Route exact path="/usuarios">
            <Vendedores />
          </Route>


          <Route exact path="/usuarios/:id">
            <Perfil />
          </Route>
          <Route exact path="/perfil">
            <Perfil />
          </Route>
          <Route path="/notificacao">
            <Notificacao />
          </Route>
          <Route path="/transacoes">
            <Transacoes />
          </Route>
          <Route path="/dashboard">
            <Dashboard />
          </Route>
          <Route exact path="/">
            <Redirect to="/dashboard" />
          </Route>
        </Switch>
      </main>
    </div>
  );
};
export default Admin;
