import React, { useEffect, useState } from "react";
import {
  BsCheckCircle, FaMoneyBillWave, MdBusinessCenter, RiTestTubeLine
} from "react-icons/all";
import { useParams } from "react-router-dom";
import Dropdown from "../../components/input/Dropdown";
import TextInput from "../../components/input/TextInput";
import { pacotesEmpresa, tiposEmpresa } from "../../constants/dictionary";
import { useAuth } from "../../context";
import { APIKit } from "../../services/api";
import { formatMoney, itemPlusTodos } from "../../utils";
import "./dashboard.css";

const Dashboard = () => {
  const { toast } = useAuth(useAuth);
  const [empresas, setEmpresas] = useState(null)
  const query = useParams();
  const [filters, setFilters] = useState({
    nome: '',
    tipo: '',
    pacote: '',
    page: 0,
  })
  const setFilter = (key, value) => {
    const data = { ...filters, [key]: value }
    if (key !== 'page') data.page = 0
    setFilters(data)
  }

  const fetchData = () => {
    APIKit.get('/dashboard', {
      params: { ...filters }
    }).then(response => setEmpresas(response.data)).catch(() => toast.error("Erro ao Buscar dados da estatísticas!"))
  }

  useEffect(() => {
    fetchData()
  }, [filters])

  useEffect(() => {
    setFilters({ ...query })
  }, [])


  return (
    <div className="container">
      <div className="w-full">
        <h2 className="gradient-text">Painel de Estatísticas</h2>
        <p>Veja o seu desempenho.</p>
      </div>

      <div className="flex-col w-full">
        <h3 className="mb-1 mt-4 gradient-text">Filtros</h3>
        <div className="grid-col-4 gap-2">
          <TextInput
            name="startDate"
            label="Data Inicial"
            type="date"
            placeholder="Data Inicial"
            inputEvent={(data) => setFilter('startDate', data)}
          />
          <TextInput
            name="endDate"
            label="Data Final"
            type="date"
            placeholder="Data Final"
            inputEvent={(data) => setFilter('endDate', data)}
          />
          <Dropdown
            name="tipo"
            items={itemPlusTodos(tiposEmpresa)}
            label="Tipo de Empresa"
            placeholder="Tipo de Empresa"
            inputEvent={(data) => setFilter('tipo', data)}
          />
          <Dropdown
            name="pacote"
            items={itemPlusTodos(pacotesEmpresa)}
            label="Pacote"
            placeholder="Pacote"
            inputEvent={(data) => setFilter('pacote', data)}
          />
        </div>
      </div>
      <div className="dashboard-card-group w-full">
        <div className="dashboard-analise-card">
          <div className="dashboard-icon">
            <BsCheckCircle color="white" size={18} />
          </div>
          <div className="dashboard-detail">
            <h2>{empresas?.todas || 0}</h2>
            <p>Total de Empresas</p>
          </div>
        </div>
        <div className="dashboard-analise-card">
          <div className="dashboard-icon">
            <RiTestTubeLine color="white" size={18} />
          </div>
          <div className="dashboard-detail">
            <h2>{empresas?.trial || 0}</h2>
            <p>Empresas Em Trial</p>
          </div>
        </div>
        <div className="dashboard-analise-card">
          <div className="dashboard-icon">
            <MdBusinessCenter color="white" size={18} />
          </div>
          <div className="dashboard-detail">
            <h2>{formatMoney(empresas?.receitaContratos) || 0}</h2>
            <p>Receita de Contratos</p>
          </div>
        </div>
        <div className="dashboard-analise-card">
          <div className="dashboard-icon">
            <FaMoneyBillWave color="white" size={18} />
          </div>
          <div className="dashboard-detail">
            <h2 className="text-base">{formatMoney(empresas?.receitaMensalidades) || 0}</h2>
            <p>Receita de Mensalidades</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
